<template>
  <div class="project-screen-slider-container left" v-animateonscroll="{ enterClass: 'fade-in'}" >
    <div class="top-container">
      <sub-title>
        {{ $t('projectScreenSlider.mainTitle') }}
      </sub-title>
    </div>
    <div class="project-screen-content">
      <div class="card-project-container"
           @click="changeState(index)"
           v-for="(item, index) in projectsInfo"
           :class="{'active' : item.active}"
           :key="index">
        <div class="info-container">
          <div class="img-container icon">
            <img :src="getUrlProject(`${ index + 1 }.svg`)" alt="bg">
          </div>
          <transition name="fade-translate-x-card">
            <div class="main-description-container" v-if="item.active">
              {{ item.description }}
            </div>
          </transition>
          <main-paragraph-card :active-h="true">
            {{ item.title }}
            <div class="img-container plus">
              <img :src="getUrlIcons('plus')" alt="plus">
            </div>
          </main-paragraph-card>
        </div>
        <div class="img-container bg color"></div>
        <div class="img-container bg">
          <img :src="getUrlProject(`${ index + 1 }.webp`)" alt="bg">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SubTitle from "@/components/shared/text/SubTitle.vue";
import MainParagraphCard from "@/components/shared/text/card/MainParagraphCard.vue";

export default {
  name: "ProjectScreenSliderNew",
  components: {MainParagraphCard, SubTitle},
  data() {
    return {
      activeCard: [false, false, false, false]
    }
  },
  computed: {
    projectsInfo() {
      return this.$tm('projectScreenSlider.projectsInfo').map((item, index) => ({
        ...item,
        active: this.activeCard[index]
      }))
    },
  },
  methods: {
    changeState(index) {
      this.activeCard[index] = !this.activeCard[index]
    },
    getUrlProject(picture) {
      return require(`@/assets/projects/${picture}`)
    }
  }
}
</script>

<style scoped lang="scss">

.img-container {
  position: relative;
  width: 100%;
  height: 100%;
  & img {
    width: 100%;
    height: 100%;
  }
}
  .project-screen-slider-container {
    display: flex;
    flex-direction: column;
    .top-container {
      position: relative;
      z-index: 3;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      .sub-title-container {
        text-transform: uppercase;
        white-space: pre-wrap;
      }
    }
    .project-screen-content {
      display: flex;
      flex-direction: row;
    }
    .card-project-container {
      cursor: pointer;
      flex-basis: 25%;
      height: 100%;
      position: relative;
      overflow: hidden;
      width: 100%;
      transition: all 1s ease;

      .main-description-container {
        position: relative;
        font-weight: 300;
        color: var(--color-font-white);
        z-index: 2;
      }
      &.active {
        .img-container {
          &.plus {
            transform: rotate(45deg);
          }
          &.color {
            opacity: 1;
          }
        }
      }
      .img-container {
        &.bg {
          z-index: 0;
          position: absolute;
          top: 0;
          left: 0;
        }

        &.plus {
          cursor: pointer;
          transition: all .3s ease;
        }

        &.color {
          z-index: 1;
          transition: all .6s ease;
          background: var(--color-dark-byzantine-blue);
          opacity: .7;
        }
      }

      .info-container {
        white-space: pre-wrap;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        z-index: 2;

        .card-main-paragraph-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-end;
          transition: all 1s ease;
          color: var(--color-font-white);
          font-weight: 600;
        }
      }
    }
  }



  @media (max-width: 3024px){
    .project-screen-slider-container {
      row-gap: 60px;
      .project-screen-content {
        column-gap: 20px;
        height: 700px;
      }
      .card-project-container {
        padding: 40px;
        border-radius: 24px;
        .main-description-container {
          font-size: 22px;
        }
        &.active {
          box-shadow: 0  0 40px rgba(var(--color-rgba-dark-byzantine-blue), .6);
        }
        &:hover {
          box-shadow: 0  0 40px rgba(var(--color-rgba-dark-byzantine-blue), .6);
        }
        .card-main-paragraph-container {
          font-size: 32px;
        }
        .img-container {
          &.icon {
            width: 74px;
            height: 74px;
          }
          &.plus {
            width: 44px;
            height: 44px;
          }
        }
      }
    }
  }


  @media (max-width: 1920px) {
    .project-screen-slider-container {
      row-gap: 50px;
      .slider-scroll-container {
        height: 600px;
      }
      .card-project-container {
        padding: 28px;
        border-radius: 18px;
        .main-description-container {
          font-size: 18px;
        }
        &.active {
          box-shadow: 0  0 30px rgba(var(--color-rgba-dark-byzantine-blue), .6);
        }
        &:hover {
          box-shadow: 0  0 30px rgba(var(--color-rgba-dark-byzantine-blue), .6);
        }
        .card-main-paragraph-container {
          font-size: 26px;
        }
        .img-container {
          &.plus {
            width: 30px;
            height: 30px;
          }
          &.icon {
            width: 60px;
            height: 60px;
          }
        }
      }
    }
  }


  @media (max-width: 1600px) {
    .project-screen-slider-container {
      row-gap: 40px;
      .project-screen-content {
        column-gap: 10px;
        height: 500px;
      }
      .card-project-container {
        padding: 24px;
        border-radius: 16px;
        .main-description-container {
          font-size: 14px;
        }
        &:hover {
          box-shadow: 0  0 25px rgba(var(--color-rgba-dark-byzantine-blue), .6);
        }
        &.active {
          box-shadow: 0  0 25px rgba(var(--color-rgba-dark-byzantine-blue), .6);
        }
        .card-main-paragraph-container {
          font-size: 22px;
        }
        .img-container {
          &.plus {
            width: 26px;
            height: 26px;
          }
          &.icon {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
  @media (max-height: 800px) {
    .project-screen-slider-container {
      row-gap: 40px;
      .project-screen-content {
        column-gap: 9px;
        height: 460px;
      }
      .card-project-container {
        padding: 16px;
        border-radius: 12px;
        .main-description-container {
          font-size: 12px;
        }
        .card-main-paragraph-container {
          font-size: 18px;
        }
        .img-container {
          &.plus {
            width: 24px;
            height: 24px;
          }
          &.icon {
            width: 34px;
            height: 34px;
          }
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .project-screen-slider-container {
      row-gap: 40px;
      .project-screen-content {
        column-gap: 10px;
        height: 500px;
      }
      .card-project-container {
        padding: 24px;
        border-radius: 16px;
        .main-description-container {
          font-size: 14px;
        }
        .card-main-paragraph-container {
          font-size: 22px;
        }
        .img-container {
          &.plus {
            width: 26px;
            height: 26px;
          }
          &.icon {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .project-screen-slider-container {
      row-gap: 40px;
      .project-screen-content {
        column-gap: 9px;
        height: 460px;
      }
      .card-project-container {
        padding: 16px;
        border-radius: 12px;
        .main-description-container {
          font-size: 12px;
        }
        .card-main-paragraph-container {
          font-size: 18px;
        }
        .img-container {
          &.plus {
            width: 24px;
            height: 24px;
          }
          &.icon {
            width: 34px;
            height: 34px;
          }
        }
      }
    }
  }

  @media (max-width: 900px) {
    .project-screen-slider-container {
      row-gap: 32px;
      .project-screen-content {
        height: 450px;
        flex-wrap: wrap;
        column-gap: 0;
        row-gap: 0;
        justify-content: space-between;
        align-content: space-between;
      }
      .card-project-container {
        height: 49%;
        padding: 16px;
        border-radius: 12px;
        flex-basis: 49%;
        &:hover {
          box-shadow: none;
        }
        &.active {
          box-shadow: none;
        }
        .main-description-container {
          font-size: 8px;
        }
        .card-main-paragraph-container {
          font-size: 12px;
        }
        .img-container {
          &.plus {
            width: 20px;
            height: 20px;
          }
          &.icon {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
@media (max-width: 900px)  and (min-height: 720px) and (max-height: 1000px) {
  .project-screen-slider-container {
    .card-project-container {
      flex-basis: 48.7%;
    }
  }
}
  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    .project-screen-slider-container {
      .card-project-container {
        .main-description-container {
          font-size: 8px;
        }
        .card-main-paragraph-container {
          font-size: 9px;
        }
        .img-container {
          &.plus {
            width: 16px;
            height: 16px;
          }
          &.icon {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  @media (min-width: 3025px) {
    .project-screen-slider-container {
      row-gap: 80px;

      .project-screen-content {
        column-gap: 40px;
        height: 1200px;
      }
      .card-project-container {
        padding: 46px;
        border-radius: 32px;
        .main-description-container {
          font-size: 38px;
        }
        &:hover {
          box-shadow: 0  0 50px rgba(var(--color-rgba-dark-byzantine-blue), .6);
        }
        &.active {
          box-shadow: 0  0 50px rgba(var(--color-rgba-dark-byzantine-blue), .6);
        }
        .card-main-paragraph-container {
          font-size: 60px;
        }
        .img-container {
          &.plus {
            width: 60px;
            height: 60px;
          }
          &.icon {
            width: 90px;
            height: 90px;
          }
        }
      }
    }
  }

</style>