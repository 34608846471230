
export default {
    computed: {
        titleDisclaimer() {
            return this.sliceText.map(item => {
                let activeHrefFirst = this.sliceColorFirstText.text.includes(item)
                let activeHrefSecond = this.sliceColorSecondText.text.includes(item);
                return {
                    text: item,
                    type: activeHrefFirst || activeHrefSecond ? 'rout' : 'text',
                    ...activeHrefFirst || activeHrefSecond
                        ? { rout: activeHrefFirst
                            ? this.sliceColorFirstText.rout
                            : this.sliceColorSecondText.rout }
                        : {}
                }
            })
        },
        sliceColorFirstText() {
            return {
                text: this.disclaimerTextFirstColor,
                rout: 'privacy_policy.pdf'
            }
        },
        sliceColorSecondText() {
            return {
                text: this.disclaimerTextSecondColor,
                rout: 'the_processing_of_personal_data.pdf'
            }
        }

    },
}