<template>
  <layout-modal-form :text-button="$t('modal.textButtonTariff')"
                     :main-title="$t('modal.mainTitleTariff')"
                     :loading="loading"
                     :disabled="!isValid"
                     @closeModal="closeModal"
                     @actionButton="sendLetter">
    <title-input v-for="(item, index) in inputInfo"
                 @changeTextInput="changeText"
                 @changeCountryCode="changeCountryCode"
                 :name-block="'form-center-container'"
                 :key="index"
                 :item="item"/>
  </layout-modal-form>
</template>

<script>
import LayoutModalForm from "@/components/entities/modals/layouts/LayoutModalForm.vue";
import {mapActions} from "vuex";
import TitleInput from "@/components/shared/TitleInput.vue";
import * as emailjs from "@emailjs/browser";

export default {
  name: "ModalDemo",
  components: {TitleInput, LayoutModalForm},

  data() {
    return {
      loading: false,
      form: {
        name: '',
        email: '',
        phone: '',
        position: '',
        company: ''
      },
      country: '',
      inputInfoStatic: [
        {
          id: 0,
          required: true,
          dataIndex: 'name',
          type: 'fname'
        },
        {
          id: 1,
          required: true,
          dataIndex: 'email',
          type: 'mail'
        },
        {
          id: 2,
          required: true,
          dataIndex: 'phone',
          mask: '+# (###) ###-##-##',
          type: 'phone'
        },
        {
          id: 3,
          dataIndex: 'company',
          type: 'text'
        },
        {
          id: 4,
          dataIndex: 'position',
          type: 'text'
        },
      ]
    }
  },
  computed: {
    isValid() {
      return this.inputInfoStatic
          .filter(item => item.required || item.type === 'mail')
          .map(item => item.type !== 'mail'
              ? this.form[item.dataIndex] !== ''
              : this.validateEmail(this.form[item.dataIndex])).every(item => item);
    },
    inputInfo() {
      return this.$tm('modalContact.info').map((item, index) => ({
        ...item,
        ...this.inputInfoStatic[index]
      }))
    }
  },
  methods: {
    ...mapActions(['IS_ACTIVE_MODAL_TARIFF']),
    closeModal() {
      this.IS_ACTIVE_MODAL_TARIFF(false)
    },
    sendLetter() {
      if(this.isValid) {
        this.loading = true;
        let form = {...this.form, phone: this.country + this.form.phone}
        emailjs.send('service_n6k3i67', 'template_dsujyjq',
            form,
            '5-bsCqqJo-rn6m-G_')
            .then((result) => {
              this.loading = false;
              console.log('SUCCESS!', result.text);
              this.closeModal();
            }, (error) => {
              this.loading = false;
              console.log('FAILED...', error);
            });
      }
    },
    changeText(value, key) {
      this.form[key] = value
    },
    changeCountryCode(code) {
      this.country = code;
    }
  }
}
</script>

<style scoped>

</style>