<template>
  <div class="main-wrapper" @scroll="checkHeaderFixed">
    <transition name="fade-opacity-lazy">
      <cookie-screen v-if="isActiveModalCookie"/>
    </transition>
    <transition name="fade-opacity-lazy">
      <modal-contact v-if="isActiveModalContact"/>
    </transition>
    <transition name="fade-opacity-lazy">
        <modal-demo v-if="isActiveModalDemo"/>
    </transition>
    <transition name="fade-opacity-lazy">
      <modal-partner v-if="isActiveModalPartner"/>
    </transition>
      <transition name="fade-opacity-lazy">
         <modal-tariff v-if="isActiveModalTariff"/>
      </transition>
      <main-header class="header-wrapper" v-if="isActiveMount" :class="{'is-active-process-screen' : isActiveProcessScreen && isMobile}" :active-fixed-header="activeFixedHeader"/>
      <router-view/>
    <main-footer id="contact"/>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import MainHeader from "@/components/widgets/MainHeader.vue";
import MainFooter from "@/components/widgets/MainFooter.vue";
import ModalContact from "@/components/entities/modals/ModalContact.vue";
import ModalDemo from "@/components/entities/modals/ModalDemo.vue";
import ModalTariff from "@/components/entities/modals/ModalTariff.vue";
import CookieScreen from "@/components/entities/cookie/CookieScreen.vue";
import ModalPartner from "@/components/entities/modals/ModalPartner.vue";
import debounce from "debounce";

export default {
  name: "MainLayout",
  components: {ModalPartner, CookieScreen, ModalTariff, ModalDemo, ModalContact, MainFooter, MainHeader},
  data() {
    return {
      scrollConfig: {
        pixel: 500,
        number: 2
      },
      ticking: false,
      activeFixedHeader: true,
      isActiveMount: false,
    }
  },
  computed: {
    ...mapState(['isActiveModalDemo', 'isActiveModalContact', 'isActiveModalTariff', 'isActiveModalCookie', 'isActiveModalPartner', 'isActiveScroll', 'isActiveProcessScreen'])
  },
  mounted() {
    if(this.$route.name !== 'main' && this.$route.name !== 'home' && this.$route.name !== 'consultation') {
      this.$nextTick(() => {
        this.activeFixedHeader = false
        this.isActiveMount = true;
      })
    } else {
      this.isActiveMount = true;
    }
    this.checkSize();
    this.checkHeaderFixed();
    this.checkLocalStorageCookie();
    addEventListener('resize', this.checkSize)
  },
  unmounted() {
    removeEventListener('scroll', this.checkHeaderFixed)
    removeEventListener('resize', this.checkSize)
  },

  methods: {
    ...mapActions(['ACTIVE_MOBILE', 'ACTIVE_IS_MINI_MOBILE', 'INNER_WIDTH', 'INNER_HEIGHT', 'IS_ACTIVE_MODAL_COOKIE', 'ACTIVE_PROCESS_SCREEN']),
    checkLocalStorageCookie() {
      if(!Boolean(localStorage.getItem('cookie_is_active'))) {
        setTimeout(() => { this.IS_ACTIVE_MODAL_COOKIE(true) }, 1000);
      }
    },
    checkSize() {
      this.innerHeight = window.innerHeight;
      document.documentElement.style.setProperty('--vh', `${this.innerHeight * 0.01}px`);
      this.innerWidth = window.innerWidth;
      document.documentElement.style.setProperty('--vw', `${this.innerWidth * 0.01}px`);
      this.INNER_WIDTH(this.innerWidth)
      this.INNER_HEIGHT(this.innerHeight)
      if(this.innerWidth <= 900) {
        this.ACTIVE_MOBILE(true)
      } else {
        this.ACTIVE_MOBILE(false)
      }
      if(this.innerWidth <= 769) {
        this.ACTIVE_IS_MINI_MOBILE(true)
      } else {
        this.ACTIVE_IS_MINI_MOBILE(false)
      }
      // this.checkPxScroll(window.innerWidth);
    },

    checkHeaderFixed: debounce(function() {
      if (!this.ticking) {
        window.requestAnimationFrame(() => {
          let parent = document.querySelector('.main-wrapper')
          let scrollY = parent.scrollTop
          let activePX = this.checkPX(window.innerWidth);
          this.activeFixedHeader = (scrollY + activePX) <= (window.innerHeight * 0.8);
          if (!this.isActiveScroll) {
            let processScreen = document.querySelector('.process-screen-wrapper')
            if (processScreen) {
              let info = processScreen.getBoundingClientRect();
              const isVisible = info.bottom > 100 && info.top < (window.innerHeight / 5);
              this.ACTIVE_PROCESS_SCREEN(isVisible)
            }
          }
          this.ticking = false;
        });
        this.ticking = true;
      }
    }, 100),

    // checkPxScroll(width) {
    //   if(width > 4000) {
    //     this.scrollConfig.pixel = 2500;
    //     this.scrollConfig.number = 3.5;
    //   } else if(width > 3023) {
    //     this.scrollConfig.pixel = 2000;
    //     this.scrollConfig.number = 3;
    //   } else if(width > 2560) {
    //     this.scrollConfig.pixel = 1800;
    //     this.scrollConfig.number = 2;
    //   }  else if (width > 1920) {
    //     this.scrollConfig.pixel = 750;
    //     this.scrollConfig.number = 2.5;
    //   } else {
    //     this.scrollConfig.pixel = 500;
    //     this.scrollConfig.number = 2;
    //   }
    // },

    checkPX(width) {
      if(width >= 3025) {
        return 1600
      }else if (width > 2559) {
        return 1080
      } else if (width > 1920) {
        return 800
      } else if (width > 1600) {
        return 60
      } else if (width > 1300) {
        return 400
      } else {
        return 24
      }
    },
  }
}

</script>

<style scoped lang="scss">
.main-wrapper {
  //scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  height: var(--calc-height);
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.header-wrapper {
  @media(max-width: 900px) {
    transform: translateY(0);
    transition: transform .3s ease;
    &.is-active-process-screen {
      transform: translateY(-58px);
    }
  }
}

</style>