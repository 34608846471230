<template>
  <button class="main-button-container" :class="{'loading': loading, 'disabled' : disabled}">
      <div class="text-container" :class="{'loading' : loading}">
        <slot/>
      </div>
    <transition name="fade-opacity-lazy">
      <loader v-if="loading"/>
    </transition>

  </button>
</template>

<script>
import Loader from "@/components/shared/Loader.vue";

export default {
  name: "MainButton",
  components: {Loader},
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped lang="scss">
  .main-button-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    transition: all .6s ease;
    cursor: pointer;
    white-space: nowrap;
    height: fit-content;
    background: var(--color-font-delft);
    color: var(--color-font-white);
    text-transform: uppercase;
    font-weight: 600;

    &.blocked {
      cursor: auto;
      &:hover {
        opacity: 1;
      }
    }

    .loader-container {
      position: absolute;
    }
    .text-container {
      transition: all .5s ease;
      &.loading {
        opacity: 0;
      }
    }

    &.dark {
      background: var(--color-font-squid);
    }

    &:hover {
      opacity: .8;
    }

    &.light {
      background: rgba(var(--color-rgba-cool-grey), .2);
      color: var(--color-font-squid)
    }

    &.border {
      border-style: solid;
      background: unset;
      color: var(--color-font-squid);
      border-color: var(--color-font-squid);
    }
  }



  @media (max-width: 3024px){
    .main-button-container {
      border-radius: 70px;
      padding: 18px 52px;
      font-size: 18px;
    }
  }


  @media (max-width: 1920px) {
    .main-button-container {
      border-radius: 60px;
      padding: 14px 48px;
      font-size: 16px;
    }
  }


  @media (max-width: 1600px) {
    .main-button-container {
      border-radius: 40px;
      padding: 12px 32px;
      font-size: 14px;
    }
  }
  @media (max-height: 800px) {
    .main-button-container {
      border-radius: 40px;
      padding: 14px 24px;
      font-size: 12px;
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .main-button-container {
      border-radius: 40px;
      padding: 12px 32px;
      font-size: 14px;
    }
  }

  @media (max-width: 1133px) {
    .main-button-container {
      border-radius: 40px;
      padding: 14px 24px;
      font-size: 12px;
    }
  }

  @media (max-width: 769px) {
    .main-button-container {
      border-radius: 40px;
      padding: 12px 24px;
      font-size: 12px;
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .main-button-container {
      border-radius: 100px;
      padding: 26px 50px;
      font-size: 28px;
    }
  }

</style>