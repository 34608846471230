<template>
  <div class="implementation-screen-container">
    <div class="implementation-screen-content main-content"
         v-animateonscroll="{ enterClass: 'fade-in-fast', rootMargin: marginRoot, threshold: 0.3}"
         @animationend="showNextItem">
      <sub-title>
        {{ $t('implementationScreen.mainTitle') }}
      </sub-title>
      <div class="bottom-container">
          <card-implementation-new v-for="item in infoCard"
                               :style="{'top' : `${sizeContainer * (item.id - 1)}px`}"
                               :class="{'reverse' : item.id % 2 !== 1, 'active' : isVisible(item.id)}"
                               :key="item.id"
                               :item="item"/>
          <icon-circle-container v-if="!isMobile" :img="'logo'" :class="{'active' : isVisible(6)}"/>
         <div class="line-container" :class="{'active' : isVisible(0)}"></div>
          <div class="line-img-container" v-if="!isMobile" :class="{'active' : isVisible(7)}">
            <img :src="getUrlImplements('line')" alt="icon">
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardImplementation from "@/components/entities/card/CardImplementation.vue";
import SubTitle from "@/components/shared/text/SubTitle.vue";
import IconCircleContainer from "@/components/shared/IconCircleContainer.vue";
import CardImplementationNew from "@/components/entities/card/CardImplementationNew.vue";

export default {
  name: "ImplementationScreen",
  components: {CardImplementationNew, IconCircleContainer, SubTitle, CardImplementation},
  data() {
    return {
      sizeContainer: 0,
      marginRoot: '200px',
      visibleIndexItem: -1,
      mounted: false
    }
  },
  computed: {
    infoCard() {
      return this.$tm('implementationScreen.infoCard').map((item, index) => (
          {
            ...item,
            id: index + 1
          }))
    }
  },
  mounted() {
    this.resizeImg();
    addEventListener('resize', this.resizeImg)
  },
  unmounted() {
    removeEventListener('resize', this.resizeImg)
  },
  methods: {
    resizeImg() {
      let width = window.innerWidth
      let height = window.innerHeight
      if(width >= 3025) {
        this.marginRoot = '350px';
        this.marginRoot = 0;
        this.sizeContainer = 457;
      } else if (width > 1920) {
        this.marginRoot = '320px';
        this.sizeContainer = 252;
      } else if (width > 1400 && height > 800)  {
        this.marginRoot = '246px';
        this.sizeContainer = 216;
      } else if (width > 1300 && height > 700) {
        this.marginRoot = '246px';
        this.sizeContainer = 216;
      }  else if (width > 1100) {
        this.marginRoot = '178px';
        this.sizeContainer = 185;
      } else if (width > 900) {
        this.marginRoot = '48px';
        this.sizeContainer = 150;
      } else {
        this.marginRoot = '48px';
        this.sizeContainer = 0
      }
    },
    showNextItem() {
      if(!this.mounted) {
        this.visibleIndexItem++
        this.mounted = true;
      }
      if (this.visibleIndexItem < this.infoCard.length + 2) {
        this.timer = setTimeout(() => {
          this.visibleIndexItem++
          this.showNextItem();
        }, 100)
      }
    },
  }
}
</script>

<style scoped lang="scss">
.line-container-absolute {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
}
  .implementation-screen-container {
    background: var(--color-dark-byzantine-blue);
    .implementation-screen-content {
      .sub-title-container {
        margin: 0 auto;
        color: var(--color-main);
        text-transform: uppercase;
      }
      .bottom-container {
        margin: 0 auto;
        position: relative;
        width: 100%;
        flex-direction: column;
        align-items: center;
        display: flex;
        .icon-circle-container {
          z-index: 2;
          position: relative;
        }
        .line-img-container {
          opacity: 0;
          position: absolute;
          top: 0;
          height: 100%;
          transition: opacity .3s ease;
          & img {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
          &.active {
            opacity: 1;
          }
        }
        .line-container {
          background: linear-gradient(0, rgba(var(--color-rgba-non-photo-blue),0) 0%, rgba(var(--color-rgba-non-photo-blue)) 11%, rgba(var(--color-rgba-non-photo-blue),1) 84%, rgba(var(--color-rgba-non-photo-blue),0) 100%);
          height: 100%;
          left: 50%;
          position: absolute;
          transition: opacity .3s ease;
          opacity: 0;
          z-index: 1;
          transform: translateX(-50%);
          &.active {
            opacity: 1;
          }
        }
        .icon-circle-container {
          position: absolute;
          opacity: 0;
          transition: all .5s ease;
          &.active {
            opacity: 1;
          }
        }

        :deep(.card-implementation-container) {
          &:first-child {
            .left-container {
              align-items: flex-start;
              .line-container {
                height: 10%;
              }
            }
          }
        }
        .card-implementation-container {
          opacity: 0;
          max-width: 52.5%;
          z-index: 2;
          position: absolute;
          align-self: flex-start;
          transition: all .3s ease;

          &:first-child {
            align-items: flex-start;
          }
          &.active {
            opacity: 1;
          }
          &:nth-child(2n) {
            align-self: flex-end;
          }
        }
      }
    }
  }


  @media (max-width: 3024px){

    .implementation-screen-container {
      .implementation-screen-content {
        row-gap: 180px;

        .bottom-container {
          height: 1900px;
          max-width: 1580px;

          .line-img-container {
            max-width: 82px;
          }
          .line-container {
            width: 3px;
          }

          .icon-circle-container {
            bottom: 56px;
          }
          .card-implementation-container {
            max-width: 52%;
          }
          :deep(.card-implementation-container) {
            &:first-child {
              .icon-circle-container {
                margin-top: 48px;
              }

              .left-container {
                .line-container {
                  margin-top: 84px;
                }
              }
            }
          }
        }
      }
    }
  }


  @media (max-width: 1920px) {
   .implementation-screen-container {
     .implementation-screen-content {
       row-gap: 146px;

       .bottom-container {
         height: 1656px;
         max-width: 1050px;

         .line-img-container {
           max-width: 82px;
         }
         .line-container {
           width: 1.5px;
         }

         .icon-circle-container {
           bottom: 28px;
         }
         .card-implementation-container {
           max-width: 52.5%;
         }
         :deep(.card-implementation-container) {
           &:first-child {
             .icon-circle-container {
               margin-top: 56px;
             }

             .left-container {
               .line-container {
                 margin-top: 84px;
               }
             }
           }
         }
       }
     }
   }
  }


  @media (max-width: 1600px) {

    .implementation-screen-container {
      .implementation-screen-content {
        row-gap: 128px;

        .bottom-container {
          height: 1640px;
          max-width: 980px;

          .line-img-container {
            max-width: 82px;
          }
          .line-container {
            width: 1.5px;
          }

          .icon-circle-container {
            bottom: 28px;
          }
          .card-implementation-container {
            max-width: 52.5%;
          }
          :deep(.card-implementation-container) {
            &:first-child {
              .icon-circle-container {
                margin-top: 48px;
              }

              .left-container {
                .line-container {
                  margin-top: 72px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-height: 800px) {
    .implementation-screen-container {
      .implementation-screen-content {
        row-gap: 100px;

        .bottom-container {
          height: 1371px;
          max-width: 980px;

          .line-img-container {
            max-width: 82px;
          }
          .line-container {
            width: 1.5px;
          }

          .icon-circle-container {
            bottom: 28px;
          }
          .card-implementation-container {
            max-width: 52.2%;
          }
          :deep(.card-implementation-container) {
            &:first-child {
              .icon-circle-container {
                margin-top: 48px;
              }

              .left-container {
                .line-container {
                  margin-top: 72px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {


    .implementation-screen-container {
      .implementation-screen-content {
        row-gap: 128px;

        .bottom-container {
          height: 1640px;
          max-width: 980px;

          .line-img-container {
            max-width: 82px;
          }
          .line-container {
            width: 1.5px;
          }

          .icon-circle-container {
            bottom: 28px;
          }
          .card-implementation-container {
            max-width: 52.5%;
          }
          :deep(.card-implementation-container) {
            &:first-child {
              .icon-circle-container {
                margin-top: 48px;
              }

              .left-container {
                .line-container {
                  margin-top: 72px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .implementation-screen-container {
      .implementation-screen-content {
        row-gap: 100px;

        .bottom-container {
          height: 1371px;
          max-width: 980px;

          .line-img-container {
            max-width: 82px;
          }
          .line-container {
            width: 1.5px;
          }

          .icon-circle-container {
            bottom: 28px;
          }
          .card-implementation-container {
            max-width: 52.15%;
          }
          :deep(.card-implementation-container) {
            &:first-child {
              .icon-circle-container {
                margin-top: 48px;
              }

              .left-container {
                .line-container {
                  margin-top: 72px;
                }
              }
            }
          }
        }
      }
    }
  }

@media (max-width: 1024px) {
  .implementation-screen-container {
    .implementation-screen-content {
      .bottom-container {
        height: 1150px;
      }
    }
  }
}

  @media (max-width: 900px) {
    .implementation-screen-container {
      .implementation-screen-content {
        margin: 0;
        max-width: 100%;
        row-gap: 24px;
        align-items: flex-start;
        .sub-title-container {
          margin: 0;
        }
        .bottom-container {
          position: relative;
          width: 100%;
          flex-direction: column;
          row-gap: 36px;
          height: fit-content;
          .line-container {
            left: 18px;
          }
          .card-implementation-container {
            position: relative;
            max-width: 100%;
            align-self: flex-start !important;
          }

          :deep(.card-implementation-container) {
            &:first-child {
              .icon-circle-container {
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }


  @media (max-width: 320px) {
   .implementation-screen-container {
    .implementation-screen-content {
      .bottom-container {
        .line-container {
          left: 20px;
        }
      }
    }
  }
  }

  @media (min-width: 3025px) {

    .implementation-screen-container {
      .implementation-screen-content {
        row-gap: 300px;

        .bottom-container {
          height: 3320px;
          max-width: 2760px;

          .line-img-container {
            max-width: 144px;
          }
          .line-container {
            width: 4px;
          }

          .icon-circle-container {
            bottom: 28px;
          }

          .card-implementation-container {
            max-width: 52%;
          }

          :deep(.card-implementation-container) {
            &:first-child {
              .icon-circle-container {
                margin-top: 100px;
              }

              .left-container {
                .line-container {
                  margin-top: 150px;
                }
              }
            }
          }
        }
      }
    }
  }

</style>