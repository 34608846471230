<template>
  <h2 class="mini-title-container">
    <slot/>
  </h2>

</template>

<script>
export default {
  name: "MiniTitle"
}
</script>

<style scoped lang="scss">
.mini-title-container {
  font-size: 32px;
  font-weight: 500;
  line-height: 140%;
  color: var(--color-font-squid)
}



@media (max-width: 3024px){
  .mini-title-container {
    font-size: 40px;
  }
}


@media (max-width: 1920px) {
  .mini-title-container {
    font-size: 32px;
  }
}


@media (max-width: 1600px) {
  .mini-title-container {
    font-size: 28px;
  }
}
@media (max-height: 800px) {
  .mini-title-container {
    font-size: 20px;
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .mini-title-container {
    font-size: 28px;
  }
}

@media (max-width: 1133px) {
  .mini-title-container {
    font-size: 20px;
  }
}

@media (max-width: 769px) {
  .mini-title-container {
    font-size: 10px !important;
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .mini-title-container {
    font-size: 10px !important;
  }
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  //se old
}

@media (min-width: 3025px) {
  .mini-title-container {
    font-size: 72px;
  }

}


</style>