<template>

  <div id="circle-container">
    <div class="circle-content"
         :class="{'active' : isActiveScreen}">
      <vue-particles
          id="tsparticles"
          @particlesLoaded ="loadScreen"
          :options="{
                    fpsLimit: 50,
                    interactivity: {
                        events: {
                            onHover: {
                                enable: true,
                                mode: 'repulse'
                            },

                        },
                        modes: {
                            repulse: {
                                distance: 75,
                                duration: 1
                            }
                        }
                    },
                    particles: {
                        color: {
                            value: '#293E6B'
                        },
                        links: {
                            color: '#192231',
                            distance: 150,
                            enable: true,
                            opacity: 0.4,
                            width: 1
                        },
                        move: {
                            direction: 'none',
                            enable: true,
                            outModes: 'bounce',
                            random: false,
                            speed: 2,
                            straight: false
                        },
                        number: {
                            density: {
                                enable: true,
                                value_area: 200
                            },
                            value: this.isMobile ? 250 : 150,
                        },
                        opacity: {
                            value: 0.7,
                            random: false,
                        },
                        shape: {
                            type: 'circle',
                            stroke: {
                              width: 0
                            }
                        },
                        size: {
                            value: this.isMobile ? 3 : 7,
                            anim: {
                              enable: false,
                              speed: 0.01,
                              sync: false
                            }
                        }
                    },
                    detectRetina: true
                }"
      />
    </div>

  </div>
</template>

<script>
export default {
  name: "CircleBackground",
  data() {
    return {
      innerHeight: 0,
      particles: [],
      isActiveScreen: false,
    }
  },
  methods: {
    loadScreen() {
      this.isActiveScreen = true;
    }
  }
}
</script>

<style scoped lang="scss">
#circle-container {
  opacity: .4;
  top: 0;
  left: 0;
  position: absolute;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  &:before {
    pointer-events: none;
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(270deg, rgba(252, 253, 255, 0) 0, rgba(252, 253, 255) 100%);
  }
}
#tsparticles {
  height: 100%;
}
.circle-content {
  height: 100%;
  transform: scale(1.1);
  opacity: 0;
  transition: all 1s ease;
  &.active {
    opacity: 1;
  }
}
</style>