export default {
    methods: {
        getUrlIcons(picture) {
            return require(`@/assets/icons/${picture}.svg`)
        },

        getUrlLogo(picture) {
            return require(`@/assets/logo/${picture}.svg`)
        },
        getUrlImg(picture) {
            return require(`@/assets/img/${picture}.webp`)
        },
        getUrlVideo(picture) {
            return require(`@/assets/img/${picture}`)
        },
        getUrlProcess(picture) {
            return require(`@/assets/process/${picture}`)
        },

        getUrlImplements(picture) {
            return require(`@/assets/implementation/${picture}.svg`)
        },

        isVisible(index) {
            return index <= this.visibleIndexItem
        },
        validateEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
        isActivePositionScroll(rout) {
            return rout === 'features' || rout === 'developer' || rout === 'support'
                ? 'center'
                : 'start'
        },
    },
    computed: {
        innerWidthCustom() {
            return this.$store.state.innerWidthCustom;
        },
        innerHeightCustom() {
            return this.$store.state.innerHeightCustom;
        },
        isMobile() {
            return this.$store.state.activeMobile
        },
    }
}