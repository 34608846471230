<template>
  <div class="result-screen-container">
    <div class="result-screen-content main-content">
      <div class="left-container" v-animateonscroll="{ enterClass: 'fade-in'}" @animationend="showNextItem">
        <sub-title>
          {{ $t('resultScreen.mainTitle') }}
        </sub-title>
        <h3>
          <mini-title-card>
            {{ $t('resultScreen.subTitle') }}
          </mini-title-card>
        </h3>
        <main-button @click="openModalContact">
          {{ $t('resultScreen.buttonText') }}
        </main-button>
      </div>
      <div class="right-container">
        <card-result v-for="item in cardsInfo"
                     :class="{'active' : isVisible(item.id)}"
                     :key="item.id"
                     :item="item"/>
      </div>
    </div>
    <transition name="fade-opacity-lazy">
      <decoration v-if="isVisible(6)"/>
    </transition>
  </div>
</template>

<script>
import SubTitle from "@/components/shared/text/SubTitle.vue";
import MiniTitleCard from "@/components/shared/text/card/MiniTitleCard.vue";
import MainButton from "@/components/shared/buttons/MainButton.vue";
import CardResult from "@/components/entities/card/CardResult.vue";
import {mapActions} from "vuex";
import Decoration from "@/components/entities/Decoration.vue";

export default {
  name: "ResultScreen",
  components: {Decoration, CardResult, MainButton, MiniTitleCard, SubTitle},
  data() {
    return {
      visibleIndexItem: -1,
      percent: [70, 85, 70, 10, 35, 90, 80, 90],
      arrow: ['down', 'down', 'up', 'up', 'down', 'down', 'down', 'up' ]
    }
  },
  computed: {
    cardsInfo() {
      return this.$tm('resultScreen.cardsInfo').map((item,index) => ({
        ...item,
        id: index,
        percent: this.percent[index],
        ...this.arrow[index] !== '' ? { direction: this.arrow[index] } : {}
      }))
    }
  },
  methods: {
    showNextItem() {
      if (this.visibleIndexItem < this.cardsInfo.length) {
        this.timer = setTimeout(() => {
          this.visibleIndexItem++
          this.showNextItem();
        }, 200)
      }
    },

  }
}
</script>

<style scoped lang="scss">
.result-screen-container {
  position: relative;
  .result-screen-content {
    position: relative;
    z-index: 1;
    flex-direction: row;
    justify-content: space-between;
    .left-container {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      .sub-title-container {
        text-transform: uppercase;
        white-space: pre-wrap;
      }
      .card-mini-title-container {
        color: var(--color-font-nickel);
        white-space: pre-wrap;
      }
    }
    .right-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      & > * {
        flex-basis: 23%;
      }
    }
  }
  .card-result-container {
    opacity: 0;
    transition: all .6s ease;
    &.active {
      opacity: 1;
    }
  }
}
.decoration-wrapper {
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;
  pointer-events: none;
}

@media (max-width: 3024px){
  .result-screen-container {
    .result-screen-content {
      .left-container {
        row-gap: 40px;
        max-width: 620px;
      }
      .right-container {
        max-width: 1260px;
        column-gap: 20px;
        row-gap: 20px;
        & > * {
          flex-basis: 23%;
        }
      }
    }
    .decoration-container {
      max-width: 1100px;
      &.first {
        right: 17%;
      }
    }
  }
}


@media (max-width: 1920px) {
  .result-screen-container {
    .result-screen-content {
      .left-container {
        row-gap: 40px;
        max-width: 470px;
      }
      .right-container {
        max-width: 1020px;
        column-gap: 20px;
        row-gap: 20px;
        & > * {
          flex-basis: 23%;
        }
      }
    }
    .decoration-container {
      max-width: 800px;
    }
  }
}


@media (max-width: 1600px) {
  .result-screen-container {
    .result-screen-content {
      .left-container {
        row-gap: 32px;
        max-width: 380px;
      }
      .right-container {
        max-width: 780px;
        column-gap: 20px;
        row-gap: 20px;
        & > * {
          flex-basis: 23%;
        }
      }
    }
    .decoration-container {
      max-width: 800px;

      &.first {
        display: none;
      }
    }
  }
}
@media (max-height: 800px) {
  .result-screen-container {
    .result-screen-content {
      .left-container {
        row-gap: 20px;
        max-width: 316px;
      }
      .right-container {
        max-width: 652px;
        column-gap: 16px;
        row-gap: 16px;
        & > * {
          flex-basis: 23%;
        }
      }
    }
    .decoration-container {
      max-width: 600px;

      &.first {
        display: none;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .result-screen-container {
    .result-screen-content {
      .left-container {
        row-gap: 32px;
        max-width: 380px;
      }
      .right-container {
        max-width: 780px;
        column-gap: 20px;
        row-gap: 20px;
        & > * {
          flex-basis: 23%;
        }
      }
    }
    .decoration-container {
      max-width: 800px;

      &.first {
        display: none;
      }
    }
  }
}

@media (max-width: 1133px) {
  .result-screen-container {
    .result-screen-content {
      .left-container {
        row-gap: 20px;
        max-width: 316px;
      }
      .right-container {
        max-width: 652px;
        column-gap: 16px;
        row-gap: 16px;
        & > * {
          flex-basis: 23%;
        }
      }
    }
    .decoration-container {
      max-width: 600px;

      &.first {
        display: none;
      }
    }
  }
}

@media (max-width: 900px) {
  .result-screen-container {
    .result-screen-content {
      flex-direction: column;
      row-gap: 48px;
      .left-container {
        row-gap: 24px;
        max-width: 316px;
      }
      .right-container {
        justify-content: space-between;
        align-content: space-between;
        max-width: 100%;
        column-gap: 0;
        row-gap: 10px;
        & > * {
          flex-basis: 49%;
        }
      }
    }
    .decoration-wrapper {
      display: none;
    }
  }
}


@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  .result-screen-container {
    .result-screen-content {
      flex-direction: column;
      row-gap: 32px;
      .left-container {
        row-gap: 20px;
      }
      .right-container {
        column-gap: 5px;
        row-gap: 5px;
      }
    }
  }
}

@media (min-width: 3025px) {
  .result-screen-container {
    .result-screen-content {
      .left-container {
        row-gap: 40px;
        max-width: 800px;
      }

      .right-container {
        max-width: 2020px;
        column-gap: 20px;
        row-gap: 20px;

        & > * {
          flex-basis: 23%;
        }
      }
    }
  }
}

</style>