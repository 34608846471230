<template>
  <div class="main-container">
    <start-screen/>
    <decoration-screen />
<!--    <what-is-video-screen/>-->
    <about-screen/>
    <process-screen-new/>
<!--    <video-screen/>-->
    <project-screen id="applications"/>
    <implementation-screen/>
    <result-screen/>
    <tariff-screen id="price"/>
    <price-screen id="calculation"/>
    <calculator-screen/>
    <support-screen v-animateonscroll="{ enterClass: 'fade-in'}"/>
  </div>
</template>

<script>
import StartScreen from "@/components/features/start-screen/StartScreen.vue";
import AboutScreen from "@/components/features/AboutScreen.vue";
import ProjectScreen from "@/components/features/ProjectScreen.vue";
import ImplementationScreen from "@/components/features/ImplementationScreen.vue";
import ResultScreen from "@/components/features/ResultScreen.vue";
import CalculatorScreen from "@/components/features/CalculatorScreen.vue";
import PriceScreen from "@/components/features/PriceScreen.vue";
import DecorationScreen from "@/components/features/DecorationScreen.vue";
import WhatIsVideoScreen from "@/components/features/WhatIsVideoScreen.vue";
import VideoScreen from "@/components/features/VideoScreen.vue";
import TariffScreen from "@/components/features/TariffScreen.vue";
import ProcessScreen from "@/components/features/ProcessScreen.vue";
import ProcessScreenNew from "@/components/features/ProcessScreenNew.vue";
import SupportScreen from "@/components/features/SupportScreen.vue";
import {mapActions} from "vuex";

export default {
  name: "main-page",
  components: {
    SupportScreen,
    ProcessScreenNew,
    ProcessScreen,
    TariffScreen,
    VideoScreen,
    WhatIsVideoScreen,
    DecorationScreen,
    PriceScreen,
    CalculatorScreen, ResultScreen, ImplementationScreen, ProjectScreen, AboutScreen, StartScreen},
  data() {
    return {
      phone: ''
    }
  },
  mounted() {
    this.mountedAction();
  },
  methods: {
    ...mapActions(['ACTIVE_SCROLL', 'TIMER_SCROLL']),
    mountedAction() {
      // this.ACTIVE_SCROLL(true)
      this.$nextTick(() => {
        let rout = this.$route.name;
        if(rout !== 'main') {
          if(rout !== 'consultation') {
            let parent = document.querySelector('.main-wrapper');
            let block = parent.querySelector(`#${ rout }`)
            let position = this.isActivePositionScroll(rout);
            block?.scrollIntoView({
              block: position,
              ...this.isMobile ? { top: block?.offsetTop }: {}
            })
          } else {
            this.openModalContact();
          }
        }
        // this.TIMER_SCROLL(setTimeout(() => {
        //   this.ACTIVE_SCROLL(false)
        // }, 1000))
      })
    }
  }
}
</script>

<style scoped>

</style>