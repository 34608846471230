<template>
  <h3 v-if="activeH" class="card-sub-title-container text-content">
    <slot/>
  </h3>
  <div v-else class="card-sub-title-container text-content">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "SubTitleCard",
  props: {
    activeH: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped lang="scss">
.card-sub-title-container {
  font-weight: 600;
  line-height: 120%;
}

@media (max-width: 3024px){
  .card-sub-title-container {
    font-size: 26px;
  }
}


@media (max-width: 1920px) {
  .card-sub-title-container {
    font-size: 20px;
  }
}


@media (max-width: 1600px) {
  .card-sub-title-container {
    font-size: 18px;
  }
}
@media (max-height: 800px) {
  .card-sub-title-container {
    font-size: 16px;
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .card-sub-title-container {
    font-size: 18px;
  }
}

@media (max-width: 1133px) {
  .card-sub-title-container {
    font-size: 16px;
  }
}

@media (max-width: 900px) {
  .card-sub-title-container {
    font-size: 14px;
  }
}

@media (max-width: 900px)  and (min-height: 720px) and (max-height: 1000px) {
  .card-sub-title-container {
    font-size: 14px;
  }
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  //se old
}

@media (min-width: 3025px) {
  .card-sub-title-container {
    font-size: 40px;
  }
}


</style>