<template>
  <div class="project-screen-container">
    <div class="project-screen-content main-content">
      <project-screen-slider-new/>
    </div>
    <project-screen-img/>
  </div>
</template>

<script>
import ProjectScreenImg from "@/components/features/project-screen/ProjectScreenImg.vue";
import ProjectScreenSliderNew from "@/components/features/project-screen/ProjectScreenSliderNew.vue";

export default {
  name: "ProjectScreen",
  components: {ProjectScreenSliderNew, ProjectScreenImg}
}
</script>

<style scoped lang="scss">
  .project-screen-container {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 0;
    padding-bottom: 120px;
  }



  @media (max-width: 3024px) and (min-width: 1921px){
    .project-screen-container {
      padding-bottom: 140px;
      .project-screen-content {
        padding: 140px 16px;
      }
    }
  }


  @media (max-width: 1920px) {
    .project-screen-container {
      padding-bottom: 120px;
    }
  }


  @media (max-width: 1600px) {
    .project-screen-container {
      padding-bottom: 80px;
    }
  }

  @media (max-height: 800px) {
    .project-screen-container {
      padding-bottom: 60px;
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .project-screen-container {
      padding-bottom: 80px;
    }
  }

  @media (max-width: 1133px) {
    .project-screen-container {
      padding-bottom: 60px;
    }
  }

  @media (max-width: 900px) {
    .project-screen-container {
      row-gap: 0;
      padding-bottom: 32px;
      .project-screen-content {
        padding: 32px 18px 32px;
      }
    }
  }

  @media (max-width: 900px)  and (min-height: 720px) and (max-height: 1000px) {
    .project-screen-container {
      row-gap: 0;
      padding-bottom: 40px;
      .project-screen-content {
        padding: 40px 22px 40px;
      }
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    .project-screen-container {
      row-gap: 0;
      padding-bottom: 32px;
      .project-screen-content {
        padding: 32px 16px 32px;
      }
    }
  }

  @media (max-width: 375px) and (min-height: 630px) {
    .project-screen-container {
      row-gap: 0;
      padding-bottom: 32px;
      .project-screen-content {
        padding: 32px 18px 32px;
      }
    }
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .project-screen-container {
      padding-bottom: 260px;
    }
  }

</style>