<template>

  <header class="header-container">
    <div class="main-header-container"
         v-if="!isActiveHeaderButton"
         @mouseenter="focusTrue"
         @mouseleave="focusFalse"
         :class="{'active-focus' : activeFixedHeader || focusParam, 'active-fixed': !activeFixedHeader}">
      <transition name="fade-opacity-lazy">
        <div class="main-header-wrapper" v-if="activeFixedHeader || activeMenu">
          <div class="main-header-content">
            <div class="logo-container" @click="scrollToTop">
              <img :src="getUrlLogo(logoImgHover)" alt="logo">
            </div>
            <menu-item :menu-info="menuInfo"
                       :active-index="activeIndex"
                       @scrollTo="scrollTo"
                       :offset="256"/>
          </div>
          <div class="action-container">
            <main-button class="light small local" @click="changeLocal">
              {{ $t('local') }}
            </main-button>
            <main-button class="light small" @click="openModalDemo">
              {{ $t('mainHeader.buttonText') }}
            </main-button>
          </div>

        </div>
        <header-logo v-else/>
      </transition>
    </div>
    <div class="main-header-container active-focus"
         v-else>
      <transition name="fade-translate-y">
        <div class="main-header-wrapper active-button" v-if="activeMenu">
          <div class="main-header-content">
            <div class="logo-container" @click="scrollToTop">
              <img :src="getUrlLogo(logoImgHover)" alt="logo">
            </div>
            <menu-item :menu-info="menuInfo"
                       :active-index="activeIndex"
                       @scrollTo="scrollTo"
                       :offset="256"/>

          </div>
        </div>
      </transition>
      <header-logo @click="openMenuTablet"/>
      <div class="action-container">
        <main-button class="light small local" @click="changeLocal">
          {{ $t('local') }}
        </main-button>
        <main-button class="light small" @click="openModalDemo">
          {{ $t('mainHeader.buttonText') }}
        </main-button>
      </div>
    </div>
  </header>
</template>

<script>
import MenuItem from "@/components/widgets/MenuItem.vue";
import MainButton from "@/components/shared/buttons/MainButton.vue";
import HeaderLogo from "@/components/entities/HeaderLogo.vue";

export default {
  name: "MainHeaderDesktopTablet",
  components: {HeaderLogo, MainButton, MenuItem},
  props: {
    activeFixedHeader: {
      type: Boolean,
      default: false,
      required: true
    },
    menuInfo: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      activeBlur: true,
      logoImgHover: 'logo',
      isActiveHeaderButton: false,
      activeIndex: -1,
      focusParam: false,
      activeMenu: false,
      timer: null,
      timerBlur: null,
    }
  },
  mounted() {
    this.resizeScreen();
    addEventListener('resize', this.resizeScreen)
    if(this.isActiveHeaderButton) {
      this.activeBlur = false
    }
  },
  unmounted() {
    removeEventListener('resize',this.resizeScreen)
  },
  methods: {
    changeLocal() {
      this.$i18n.locale === 'en' ? this.$i18n.locale = 'ru' :  this.$i18n.locale = 'en'
      document.documentElement.lang = this.$i18n.locale
    },
    resizeScreen() {
      this.isActiveHeaderButton = window.innerWidth < 1500 && window.innerHeight <= 800 || window.innerWidth < 1100;
    },
    focusTrue() {
      clearTimeout(this.timer)
      this.focusParam = true
      this.timer = setTimeout(() => {
        this.activeMenu = true
      }, 350)
    },

    focusFalse() {
      clearTimeout(this.timer)
        this.focusParam = false
        this.activeMenu = false
    },


    scrollToTop() {
      this.$emit('scrollToTop')
    },

    scrollTo(item) {
      this.$emit('scrollTo', item)
    },

    openMenuTablet() {
      this.activeMenu = !this.activeMenu
    }
  }
}
</script>

<style scoped lang="scss">

.header-container {
  pointer-events: none;
  position: fixed;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 900;
}

.main-header-container {
  height: inherit;
  pointer-events: auto;
  position: relative;
  overflow: hidden;
  transition: all .6s ease;
  &.active-focus {
    width: 100% !important;
    border-radius: 10px;
    background: unset;
  }
}
.main-header-wrapper {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &.active-button {
    width: auto;
    position: absolute;
    bottom: -64px;

    .menu-content {
      column-gap: 24px;
    }
  }

}
.main-header-content {
  background: #E8EBF1;
  justify-content: space-between;
  transition: all .5s ease;
  z-index: 1;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  //background: rgba(var(--color-rgba-cool-grey), .2);
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  &.active-blur {
    backdrop-filter: blur(10px);
  }
  .scrollactive-item {
    &.active {
      .main-paragraph-container {
        opacity: 1;
      }
    }
  }




}
.logo-container {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &.icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & img {
    width: 100%;
    height: 100%;
  }
}
.main-button-container {
  background: #E8EBF1 !important;
  //backdrop-filter: blur(20px);
  height: inherit !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.action-container {
  column-gap: 16px;
  height: inherit;
  display: flex;
  flex-direction: row;
  .main-button-container {
    &.local {
      width: 75px;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.menu-content {
  position: relative;
  z-index: 1;
}


@media (max-width: 3024px){
  .header-container {
    top: 60px;
    max-width: 1914px;
    height: 65px !important;
  }

  .main-header-container {
    width: 86px !important;
    &.active-focus {
      border-radius: 50px;
    }
  }
  .main-header-wrapper {
    column-gap: 36px;
  }
  .main-header-content {
    padding: 20px 28px;
    border-radius: 50px;
    column-gap: 140px;


    .logo-container {
      min-width: 127px;
      max-width: 127px;
      height: 25px;
    }
  }

}


@media (max-width: 1920px) and (min-width: 1601px){
  .header-container {
    top: 60px;
    max-width: 1554px;
    height: 55px !important;
  }

  .main-header-container {
    width: 75px !important;
    &.active-focus {
      border-radius: 10px;
    }
  }
  .main-header-wrapper {
    column-gap: 20px;
  }
  .main-header-content {
    padding: 17px 24px;
    border-radius: 50px;
    column-gap: 62px;


    .logo-container {
      min-width: 109px;
      max-width: 109px;
      height: 21px;
    }
  }
  .main-button-container {
    padding-left: 55px;
    padding-right: 55px;
  }

}



@media (max-width: 1600px) {
  .header-container {
    top: 40px;
    max-width: 1194px;
    height: 51px !important;
  }

  .main-header-container {
    width: 62px !important;
    &.active-focus {
      border-radius: 50px;
    }
  }
  .main-header-wrapper {
    column-gap: 16px;
  }
  .main-header-content {
    padding: 16px;
    border-radius: 50px;
    column-gap: 40px;


    .logo-container {
      min-width: 97px;
      max-width: 97px;
      height: 19px;
    }
    .main-button-container {
      padding-left: 29px;
      padding-right: 29px;
    }
  }
}

@media (max-height: 800px) {
  .header-container {
    max-width: 1008px;
  }
  .main-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: visible;
  }

  .main-button-container {
    padding-left: 35px;
    padding-right: 35px;
  }
  .main-header-content {
    column-gap: 32px;
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .header-container {
    top: 40px;
    max-width: 1194px;
    height: 51px !important;
  }

  .main-header-container {
    width: 62px !important;
    &.active-focus {
      border-radius: 50px;
    }
  }
  .main-header-wrapper {
    column-gap: 16px;
  }
  .main-header-content {
    padding: 16px;
    border-radius: 50px;
    column-gap: 40px;


    .logo-container {
      min-width: 97px;
      max-width: 97px;
      height: 19px;
    }

    .main-button-container {
      padding-left: 29px;
      padding-right: 29px;
    }
  }
}

@media (max-width: 1133px) {
  .header-container {
    max-width: 1008px;
  }
  .main-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: visible;
  }

  .main-button-container {
    padding-left: 35px;
    padding-right: 35px;
  }
  .main-header-content {
    column-gap: 32px;
  }
}
@media (max-width: 769px) {
  //14
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //plus
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  //se old
}

@media (min-width: 3025px) {
  .header-container {
    top: 200px;
    max-width: 3334px;
    height: 99px !important;
  }

  .main-header-container {
    width: 132px !important;
    &.active-focus {
      border-radius: 66px;
    }
  }
  .main-header-wrapper {
    column-gap: 140px;
  }
  .main-header-content {
    max-width: 2460px;
    padding: 30px 42px;
    border-radius: 66px;
    column-gap: 140px;


    .logo-container {
      min-width: 192px;
      max-width: 192px;
      height: 39px;
    }
  }

  .main-button-container {
    width: 415px;
  }

  .main-button-container {
    &.local {
      width: 128px !important;
    }
  }
}

</style>