<template>
  <h2 v-if="activeH" class="color-text-container">
    <span v-for="(item, index) in sliceText" :key="index" :active-h="true"
          :class="{'active-color-text' : sliceColorText.includes(item), 'active-split' : item === ','}">
      {{ item }}
    </span>
  </h2>
  <div v-else class="color-text-container">
        <span v-for="(item, index) in sliceText" :key="index" :active-h="false"
              :class="{'active-color-text' : sliceColorText.includes(item), 'active-split' : item === ','}">
      {{ item }}
    </span>
  </div>
</template>

<script>
import SubTitle from "@/components/shared/text/SubTitle.vue";

export default {
  name: "ColorText",
  components: {SubTitle},
  props: {
    textInfo: {
        type: String,
        required: true
    },
    activeColorText: {
      type: String,
      required: true
    },
    activeH: {
      type: Boolean,
      default: true
    }
  },
  computed: {
      sliceText() {
        return this.textInfo.split(/(\s+)/);
      },
      sliceColorText() {
        return this.activeColorText.split(/(\s+)/);
      }
    },
}
</script>

<style scoped lang="scss">
  .color-text-container {
    font-weight: 500;
    line-height: 140%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-transform: uppercase;
    color: var(--color-font-squid);
    .active-color-text {
      color: var(--color-font-delft)
    }
  }



  @media (max-width: 3024px){
    .color-text-container {
      font-size: 52px;
      column-gap: 9px;
      .active-split {
        margin-left: -18px;
      }
    }
  }


  @media (max-width: 1920px) {
    .color-text-container {
      font-size: 44px;
      column-gap: 4px;
      .active-split {
        margin-left: -12px;
      }
    }
  }


  @media (max-width: 1600px) {
    .color-text-container {
      font-size: 32px;
      column-gap: 3px;
      .active-split {
        margin-left: -6px;
      }
    }
  }
  @media (max-height: 800px) {
    .color-text-container {
      font-size: 26px;
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .color-text-container {
      font-size: 32px;
      column-gap: 3px;
      .active-split {
        margin-left: -6px;
      }
    }
  }

  @media (max-width: 1133px) {
    .color-text-container {
      font-size: 26px;
    }
  }

  @media (max-width: 900px) {
    .color-text-container {
      column-gap: 2px;
      .active-split {
        margin-left: -4px;
      }
    }
  }

  @media (max-width: 769px) {
    .color-text-container {
      font-size: 18px;
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .color-text-container {
      font-size: 80px;
      column-gap: 12px;
      .active-split {
        margin-left: -24px;
      }
    }
  }

</style>