<template>
  <layout-modal-background @closeModal="closeModal">
    <div class="modal-layout-content">
      <div class="header-layout">
        <main-paragraph>
          {{ mainTitle }}
        </main-paragraph>
        <div class="cross-container" @click="closeModal">
          <img :src="getUrlIcons('cross')" alt="cross">
        </div>
      </div>
      <form class="form-center-container" ref="formRef">
        <slot/>

        <div class="disclaimer-content" v-if="activeDisclaimer">
          <mini-paragraph
              v-for="(item, index) in $tm('modalLayout.disclaimerInfo')"
              :class="{'active' : item === '*'}"
              :key="index">
            {{ item }}
          </mini-paragraph>
        </div>
      </form>

      <div class="action-container" :class="{'reverse' : !activeDisclaimer}">
        <main-button class="modal dark"
                     @click.native="sendInfo"
                     :loading="loading"
                     :disabled="disabled">
          {{ textButton }}
        </main-button>
        <div class="disclaimer-container">
          <mini-paragraph v-for="(item, index) in titleDisclaimer"
                          :key="index">
            <span v-if="item.type === 'text'">
            {{ item.text }}
            </span>
            <a v-else :href="item.rout" target="_blank">
              {{ item.text }}
            </a>
          </mini-paragraph>
        </div>

      </div>
    </div>
  </layout-modal-background>
</template>

<script>
import MainTitleCard from "@/components/shared/text/card/MainTitleCard.vue";
import MainButton from "@/components/shared/buttons/MainButton.vue";
import MiniParagraph from "@/components/shared/text/card/MiniParagraph.vue";
import MainParagraph from "@/components/shared/text/MainParagraph.vue";
import LayoutModalBackground from "@/components/entities/modals/layouts/LayoutModalBackground.vue";
import privacyMixin from "@/mixins/privacy.mixin";

export default {
  name: "LayoutModalForm",
  components: {LayoutModalBackground, MainParagraph, MiniParagraph, MainButton, MainTitleCard},
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: true
    },
    mainTitle: {
      type: String,
      required: true
    },
    textButton: {
      type: String,
      required: true
    },
    activeDisclaimer: {
      type: Boolean,
      default: true
    }
  },
  mixins: [privacyMixin],
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    sendInfo() {
      if(!this.disabled) {
        this.$emit('actionButton')
      }
    }
  },
  computed: {
    sliceText() {
      return this.$t('modalLayout.disclaimerText').split('*');
    },
    disclaimerTextFirstColor() {
      return this.$t('modalLayout.disclaimerTextFirstColor');
    },

    disclaimerTextSecondColor() {
      return this.$t('modalLayout.disclaimerTextSecondColor')
    },
  }
}
</script>

<style scoped lang="scss">

  .modal-layout-content {
    z-index: 991;
    background-color: var(--color-font-white);
    .header-layout {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .card-main-title-container {
        color: var(--color-font-delft)
      }
      .cross-container {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all .3s ease;
        &:hover {
          opacity: .8;
        }
        & img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .form-center-container {
      display: flex;
      flex-direction: column;

      .disclaimer-content {
        display: flex;
        flex-direction: row;
        .card-mini-paragraph-container {
          &.active {
            font-style: unset;
            color: var(--color-red);
          }
        }
      }
    }
    .disclaimer-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .card-mini-paragraph-container {
        & a {
          text-decoration: underline;
          color: inherit;
        }
      }
    }
    .action-container {
      display: flex;
      flex-direction: column;
      &.reverse {
        flex-direction: column-reverse;
      }
      .main-button-container {
        width: 100%;
      }
    }
  }




@media (max-width: 3024px){
    .modal-layout-content {
      box-shadow: 0 10px 50px rgba(0,0,0,.25);
      width: 882px;
      border-radius: 44px;
      padding: 46px;
      .header-layout {
        margin-bottom: 22px;

        .main-paragraph-container {
          font-size: 32px;
        }
        .cross-container {
          padding: 13px;
          width: 44px;
          height: 44px;
        }
      }
      .form-center-container {
        margin-bottom: 46px;
        row-gap: 28px;

        .disclaimer-content {
          font-size: 22px !important;
          column-gap: 6px;
        }
      }
      .action-container {
        row-gap: 18px;
      }
    }
}


@media (max-width: 1920px) {

    .modal-layout-content {
      box-shadow: 0 10px 45px rgba(0,0,0,.25);
      width: 644px;
      border-radius: 32px;
      padding: 32px;
      .header-layout {
        margin-bottom: 16px;
        .main-paragraph-container {
          font-size: 24px;
        }
        .cross-container {
          padding: 7px;
          width: 32px;
          height: 32px;
        }
      }
      .form-center-container {
        margin-bottom: 32px;
        row-gap: 16px;

        .disclaimer-content {
          font-size: 14px !important;
        }
      }
      .action-container {
        row-gap: 12px;
      }
    }

}


@media (max-width: 1600px) {
    .modal-layout-content {
      width: 644px;
      border-radius: 32px;
      padding: 32px;
      .header-layout {
        margin-bottom: 16px;
        .main-paragraph-container {
          font-size: 24px;
        }
        .cross-container {
          padding: 7px;
          width: 32px;
          height: 32px;
        }
      }
      .form-center-container {
        margin-bottom: 32px;
        row-gap: 16px;

        .disclaimer-content {
          font-size: 14px !important;
        }
      }
      .action-container {
        row-gap: 12px;
      }
    }
}

@media (max-height: 800px) {
    .modal-layout-content {
      width: 444px;
      border-radius: 20px;
      padding: 24px;
      .header-layout {
        margin-bottom: 12px;
        .main-paragraph-container {
          font-size: 22px;
        }
        .cross-container {
          padding: 6px;
          width: 28px;
          height: 28px;
        }
      }
      .form-center-container {
        margin-bottom: 32px;
        row-gap: 14px;

        .disclaimer-content {
          font-size: 14px !important;
        }
      }
      .action-container {
        row-gap: 16px;
      }
    }
}

@media (max-width: 1400px) and (min-height: 801px) {
    .modal-layout-content {
      width: 644px;
      border-radius: 32px;
      padding: 32px;
      .header-layout {
        margin-bottom: 16px;
        .main-paragraph-container {
          font-size: 24px;
        }
        .cross-container {
          padding: 7px;
          width: 32px;
          height: 32px;
        }
      }
      .form-center-container {
        margin-bottom: 32px;
        row-gap: 16px;

        .disclaimer-content {
          font-size: 14px !important;
        }
      }
      .action-container {
        row-gap: 12px;
      }
    }
}

@media (max-width: 1133px) {
  .modal-layout-content {
    width: 444px;
    border-radius: 20px;
    padding: 24px;
    .header-layout {
      margin-bottom: 12px;
      .main-paragraph-container {
        font-size: 22px;
      }
      .cross-container {
        padding: 6px;
        width: 28px;
        height: 28px;
      }
    }
    .form-center-container {
      margin-bottom: 32px;
      row-gap: 14px;

      .disclaimer-content {
        font-size: 14px !important;
      }
    }
    .action-container {
      row-gap: 16px;
    }
  }
}

@media (max-width: 900px) {
  .modal-layout-container {
    padding: 0 18px;
    &.cookie {
      .modal-layout-content {
        .action-container {
          margin-top: 26px;
          row-gap: 12px;
          .card-mini-paragraph-container {
            font-size: 10px !important;
          }
        }
      }
    }
    .modal-layout-content {
      max-width: 320px;
      display: flex;
      flex-direction: column;
      padding: 18px;
      .header-layout {
        margin-bottom: 18px;
        .main-paragraph-container {
          font-size: 18px;
        }
        .cross-container {
          padding: 7px;
          width: 28px;
          height: 28px;
        }
      }
      .form-center-container {
        margin-bottom: 0;
        row-gap: 12px;

        .disclaimer-content {
          margin-top: -4px;
          column-gap: 4px;
          .card-mini-paragraph-container {
            font-size: 12px !important;
          }
        }
      }
      .action-container {
        margin-top: 28px;
        row-gap: 16px;
        .card-mini-paragraph-container {
          font-size: 11px !important;
        }
      }
    }
  }
}
  @media (max-width: 900px)  and (min-height: 720px) and (max-height: 1000px) {
    .modal-layout-container {
      padding: 0 22px;
      .modal-layout-content {
        padding: 22px;
        .action-container {
          margin-top: 32px;
          row-gap: 20px;
        }
      }
    }
  }

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  .modal-layout-container {
    padding: 0 16px;
    .modal-layout-content {
      padding: 16px;
      .form-center-container {
        .disclaimer-content {
          .card-mini-paragraph-container {
            font-size: 11px !important;
          }
        }
      }
      .action-container {
        margin-top: 24px;
        row-gap: 12px;
        .card-mini-paragraph-container {
          font-size: 10px !important;
        }
      }
    }
  }
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  .modal-layout-container {
    padding: 0 12px;
    &.cookie {
      .modal-layout-content {
        .action-container {
          margin-top: 26px;
          row-gap: 12px;
          .card-mini-paragraph-container {
            font-size: 10px !important;
          }
        }
      }
    }
    .modal-layout-content {
      padding: 12px;
      .header-layout {
        margin-bottom: 12px;
        .main-paragraph-container {
          font-size: 13px;
        }
        .cross-container {
          padding: 6px;
          width: 24px;
          height: 24px;
        }
      }
      .form-center-container {
        margin-bottom: 0;
        row-gap: 8px;

        .disclaimer-content {
          margin-top: -4px;
          column-gap: 4px;
          .card-mini-paragraph-container {
            font-size: 9px !important;
          }
        }
      }
      .action-container {
        margin-top: 16px;
        row-gap: 12px;
        .main-button-container {
          padding: 10px;
          font-size: 9px !important;
        }
        .card-mini-paragraph-container {
          font-size: 9px !important;
        }
      }
    }
  }
}

@media (min-width: 3025px) {
  .card-mini-paragraph-container {
    font-size: 38px !important;
  }
    .modal-layout-content {
      box-shadow: 0 10px 50px rgba(0,0,0,.25);
      width: 1414px;
      border-radius: 72px;
      padding: 74px;
      .header-layout {
        margin-bottom: 32px;

        .main-paragraph-container {
          font-size: 50px;
        }
        .cross-container {
          padding: 21px;
          width: 72px;
          height: 72px;
        }
      }
      .form-center-container {
        margin-bottom: 74px;
        row-gap: 32px;

        .disclaimer-content {
          column-gap: 12px;
        }
      }
      .action-container {
        row-gap: 30px;
      }
    }
  }

</style>