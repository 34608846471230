<template>
  <div class="price-screen-container">
    <div class="price-screen-content main-content" v-animateonscroll="{ enterClass: 'fade-in'}">
      <div class="left-container">
        <sub-title>
          {{ $t('priceScreen.mainTitle') }}
        </sub-title>
        <main-title-card>
          {{ $t('priceScreen.subTitle') }}
        </main-title-card>
        <div class="form-container">
          <mini-title-card>
            {{ $t('priceScreen.miniTitle') }}
          </mini-title-card>

          <form class="form-content">
            <input-with-line v-for="(item, index) in inputInfo"
                             @changeTextInput="(value, key) => form[key] = value"
                             :key="index"
                             :name-block="'form-content'"
                             :info="item"/>
            <div class="confidentiality-container">
              <div class="confidentiality-content">
                <check-box :is-active="confidentialityInfo[0]" @changeStatus="changeConfidentiality(0)"/>
                <div class="disclaimer-container">
                  <mini-paragraph v-for="(item, index) in titleDisclaimer"
                                  :key="index">
                    <span v-if="item.type === 'text'">
                    {{ item.text }}
                    </span>
                    <a v-else :href="item.rout" target="_blank">
                      {{ item.text }}
                    </a>
                  </mini-paragraph>
                </div>
              </div>
              <div class="confidentiality-content">
                <check-box :is-active="confidentialityInfo[1]" @changeStatus="changeConfidentiality(1)"/>
                <mini-paragraph >
                  {{ $t('priceScreen.confidentialityInfoTitle') }}
                </mini-paragraph>
              </div>
            </div>
            <div class="actions-container">
              <transition name="fade-opacity-lazy" mode="out-in">
                <main-button v-if="!isActiveRequest" class="dark" @click="sendInfo" :loading="loading" :disabled="!isValid">
                  {{ $t('priceScreen.textButton') }}
                </main-button>
                <mini-title-card v-else class="button-title result-title">
                  {{ $t('priceScreen.resultText') }}
                </mini-title-card>
              </transition>
              <mini-title-card class="button-title" @click="openModalTariff">
                {{ $t('priceScreen.textTitle') }}
                <div class="img-container more">
                  <img :src="getUrlIcons('more')" alt="download">
                </div>
              </mini-title-card>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="right-container" v-animateonscroll="{ enterClass: 'fade-in'}">
      <div class="img-container logo">
        <img :src="getUrlLogo('logo-item-with-text')" loading="lazy" alt="logo">
      </div>
      <div class="img-container bg">
        <img src="@/assets/img/waves.png" alt="logo" loading="lazy">
      </div>
    </div>
  </div>
</template>

<script>
import SubTitle from "@/components/shared/text/SubTitle.vue";
import MainTitleCard from "@/components/shared/text/card/MainTitleCard.vue";
import MiniTitleCard from "@/components/shared/text/card/MiniTitleCard.vue";
import InputWithLine from "@/components/shared/input/InputWithLine.vue";
import CheckBox from "@/components/shared/CheckBox.vue";
import MiniParagraph from "@/components/shared/text/card/MiniParagraph.vue";
import MainButton from "@/components/shared/buttons/MainButton.vue";
import privacyMixin from "@/mixins/privacy.mixin";
import MainTitle from "@/components/shared/text/MainTitle.vue";
import * as emailjs from "@emailjs/browser";

export default {
  name: "PriceScreen",
  components: {MainTitle, MainButton, MiniParagraph, CheckBox, InputWithLine, MiniTitleCard, MainTitleCard, SubTitle},
  data() {
    return {
      isActiveRequest: false,
      loading: false,
      confidentialityInfo: [false, false],
      form: {
        name: '',
        email: '',
        phone: '',
      },
      inputInfoStatic: [
        {
          id: 0,
          dataIndex: 'name',
          required: true,
          type: 'fname'
        },
        {
          id: 1,
          dataIndex: 'email',
          required: true,
          type: 'mail'
        },
        {
          id: 2,
          dataIndex: 'phone',
          required: true,
          type: 'phone'
        }
      ]
    }
  },
  mixins: [privacyMixin],
  computed: {
    inputInfo() {
      return this.$tm('priceScreen.inputInfo').map((item, index) => ({
        placeholder: item,
        ...this.inputInfoStatic[index]
      }))
    },
    isValid() {
      return this.inputInfoStatic
          .filter(item => item.required || item.type === 'mail')
          .map(item => item.type !== 'mail'
              ? this.form[item.dataIndex] !== ''
              : this.validateEmail(this.form[item.dataIndex])).every(item => item) && this.confidentialityInfo.every(item => item)
    },
    sliceText() {
      return this.$t('priceScreen.disclaimerText').split('*');
    },
    disclaimerTextFirstColor() {
      return this.$t('priceScreen.disclaimerTextFirstColor');
    },

    disclaimerTextSecondColor() {
      return this.$t('priceScreen.disclaimerTextSecondColor')
    },
  },
  methods: {
    sendInfo() {
      this.loading = true;
      emailjs.send('service_n6k3i67', 'template_dsujyjq',
          this.form,
          '5-bsCqqJo-rn6m-G_')
          .then((result) => {
            this.loading = false;
            this.isActiveRequest = true;
            console.log('SUCCESS!', result.text);
          }, (error) => {
            this.loading = false;
            console.log('FAILED...', error);
          });
    },
    changeConfidentiality(index) {
      this.confidentialityInfo[index] = !this.confidentialityInfo[index]
    }
  }
}
</script>

<style scoped lang="scss">
.img-container {
  width: 100%;
  height: 100%;
  & img {
    width: 100%;
    height: 100%;
  }
}
.disclaimer-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  .card-mini-paragraph-container {
    & a {
      text-decoration: underline;
      color: inherit;
    }
  }
}
  .price-screen-container {
    overflow: hidden;
    position: relative;
    .price-screen-content {
      position: relative;
      z-index: 1;
      .left-container {
        display: flex;
        flex-direction: column;
        .card-main-title-container {
          color: var(--color-font-delft)
        }

        .form-container {
          .card-mini-title-container {
            color: var(--color-font-nickel);
          }
          .confidentiality-container {
            display: flex;
            flex-direction: column;

            .confidentiality-content {
              display: flex;
              flex-direction: row;
              align-items: center;
            }
          }

          .actions-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .card-mini-title-container {
              margin-bottom: 0 !important;
            }

            .button-title {
              display: flex;
              flex-direction: row;
              align-items: center;
              font-weight: 700;

              .img-container {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                & img {
                  object-fit: contain;
                }
              }
            }
          }
        }
      }
    }
    .right-container {
      will-change: opacity;
      transform: translateZ(0);
      max-width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 0;

      .img-container {
        &.logo {
          position: relative;
          z-index: 1;
        }
        &.bg {
          z-index: 0;
          left: 0;
          top: 0;
          position: absolute;
        }
      }
    }
  }



@media (max-width: 3024px){
  .result-title {
    height: 57px;
  }
  .disclaimer-container {
    max-width: 530px;
  }
  .price-screen-container {
    .price-screen-content {
      row-gap: 16px;
      .left-container {
        row-gap: 40px;
        max-width: 780px;

        .form-container {
          .card-mini-title-container {
            margin-bottom: 14px;
          }
          .confidentiality-container {
            row-gap: 14px;
            margin-top: 28px;
            .confidentiality-content {
              column-gap: 16px;
            }
          }

          .actions-container {
            margin-top: 50px;

            .button-title {
              column-gap: 16px;

              .img-container {
                max-width: 34px;
                min-width: 34px;
                max-height: 34px;
              }
            }
          }
        }
      }
    }
    .right-container {
      .img-container {
        &.logo {
          max-width: 332px;
          max-height: 228px;
        }
      }
    }
  }
}

@media (max-width: 1920px) {
  .result-title {
    height: 47px;
  }
  .disclaimer-container {
    max-width: 470px;
  }
  .price-screen-container {
    .price-screen-content {
      .left-container {
        row-gap: 40px;
        max-width: 630px;

        .form-container {
          .card-mini-title-container {
            margin-bottom: 10px;
          }
          .confidentiality-container {
            row-gap: 10px;
            margin-top: 24px;
            .confidentiality-content {
              column-gap: 24px;
            }
          }

          .actions-container {
            margin-top: 50px;

            .button-title {
              column-gap: 16px;

              .img-container {
                max-width: 30px;
                min-width: 30px;
                max-height: 30px;
              }
            }
          }
        }
      }
    }
    .right-container {
      .img-container {
        &.logo {
          max-width: 333px;
          max-height: 228px;
        }
      }
    }
  }
}


@media (max-width: 1600px) {
  .result-title {
    height: 40px;
  }
  .price-screen-container {
    .price-screen-content {
      .left-container {
        row-gap: 32px;
        max-width: 580px;

        .form-container {
          .card-mini-title-container {
            margin-bottom: 10px;
          }
          .confidentiality-container {
            row-gap: 10px;
            margin-top: 24px;
            .confidentiality-content {
              column-gap: 16px;
            }
          }

          .actions-container {
            margin-top: 50px;

            .button-title {
              column-gap: 16px;

              .img-container {
                max-width: 20px;
                min-width: 20px;
                max-height: 20px;
              }
            }
          }
        }
      }
    }
    .right-container {
      max-width: 45%;
      .img-container {
        &.logo {
          max-width: 260px;
          max-height: 178px;
        }
      }
    }
  }
}
@media (max-height: 800px) {
  .result-title {
    height: 42px;
  }
  .price-screen-container {
    .price-screen-content {
      .left-container {
        row-gap: 16px;
        max-width: 420px;

        .form-container {
          .card-mini-title-container {
            margin-bottom: 12px;
          }
          .confidentiality-container {
            row-gap: 10px;
            margin-top: 24px;
            .confidentiality-content {
              column-gap: 16px;
            }
          }

          .actions-container {
            margin-top: 50px;
            column-gap: 16px;

            .main-button-container {
              padding-left: 16px;
              padding-right: 16px;
            }

            .button-title {
              column-gap: 16px;
            }
          }
        }
      }
    }
    .right-container {
      max-width: 48%;
      .img-container {
        &.logo {
          max-width: 202px;
          max-height: 138px;
        }
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .result-title {
    height: 40px;
  }
  .price-screen-container {
    .price-screen-content {
      .left-container {
        row-gap: 32px;
        max-width: 580px;

        .form-container {
          .card-mini-title-container {
            margin-bottom: 10px;
          }
          .confidentiality-container {
            row-gap: 10px;
            margin-top: 24px;
            .confidentiality-content {
              column-gap: 16px;
            }
          }

          .actions-container {
            margin-top: 50px;

            .button-title {
              column-gap: 16px;
            }
          }
        }
      }
    }
    .right-container {

      max-width: 45%;
      .img-container {
        &.logo {
          max-width: 260px;
          max-height: 178px;
        }
      }
    }
  }
}

@media (max-width: 1133px) {
  .result-title {
    height: 42px;
  }
  .price-screen-container {
    .price-screen-content {
      .left-container {
        row-gap: 16px;
        max-width: 420px;

        .form-container {
          .card-mini-title-container {
            margin-bottom: 12px;
          }
          .confidentiality-container {
            row-gap: 10px;
            margin-top: 24px;
            .confidentiality-content {
              column-gap: 16px;
            }
          }

          .actions-container {
            column-gap: 16px;
            margin-top: 50px;

            .main-button-container {
              padding-left: 16px;
              padding-right: 16px;
            }

            .button-title {
              column-gap: 16px;
            }
          }
        }
      }
    }
    .right-container {

      max-width: 48%;
      .img-container {
        &.logo {
          max-width: 202px;
          max-height: 138px;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .result-title {
    height: 38px;
  }
  .price-screen-container {
    .price-screen-content {
      .left-container {
        row-gap: 16px;
        max-width: 100%;

        .form-container {
          .card-mini-title-container {
            margin-bottom: 12px;
          }
          .confidentiality-container {
            row-gap: 10px;
            margin-top: 24px;
            .confidentiality-content {
              column-gap: 16px;
            }
          }

          .actions-container {
            flex-direction: column;
            row-gap: 20px;
            margin-top: 24px;

            .main-button-container {
              width: 223px;
            }

            .button-title {
              column-gap: 8px;

              .img-container {
                max-width: 20px;
                min-width: 20px;
                max-height: 20px;
              }
            }
          }
        }
      }
    }
    .right-container {
      display: none;
    }
  }
}

@media (max-width: 900px)  and (min-height: 720px) and (max-height: 1000px) {

}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  //se old
}

@media (min-width: 3025px) {
  .result-title {
    height: 85px;
  }
  .price-screen-container {
    .price-screen-content {
      .left-container {
        row-gap: 60px;
        max-width: 1360px;

        .form-container {
          .card-mini-title-container {
            margin-bottom: 20px;
          }
          .confidentiality-container {
            row-gap: 14px;
            margin-top: 40px;
            .confidentiality-content {
              column-gap: 16px;
            }
          }

          .actions-container {
            margin-top: 70px;

            .button-title {
              column-gap: 16px;

              .img-container {
                max-width: 40px;
                min-width: 40px;
                max-height: 40px;
              }
            }
          }
        }
      }
    }
    .right-container {

      .img-container {
        &.logo {
          max-width: 485px;
          max-height: 332px;
        }
      }
    }
  }
}

</style>