<template>
  <div class="process-screen-wrapper"
       :style="{'--static-height' : `${staticHeight}px`}">
      <div class="process-screen-container"
           v-animateonscroll="{ enterClass: 'fade-in'}"
           @animationend="startProcessScreen">
        <div class="process-screen-content">
          <div class="main-title-container">
            {{ $t('processScreen.mainTitle') }}
          </div>
          <div class="process-screen-items" v-if="isLoading">
            <transition-group name="fade-translate-x-card">
              <card-process v-for="(item, index) in filterProcess"
                            :key="item.id"
                            :item="item"/>
            </transition-group>
          </div>
        </div>
        <div class="bg-container" v-if="isLoading" :class="{'active' : !isLoadingVideo}">
          <transition name="fade-opacity-lazy">
            <video class="video"
                   ref="video"
                   :key="activeDirection"
                   @loadeddata="loadVideo"
                   @ended="updateInterval"
                   playsinline
                   preload="auto"
                   muted>
              <source :src="getUrlProcess(`${activeVideo}.webm`)" type="video/webm"/>
              <source :src="getUrlProcess(`${activeVideo}.mp4`)" type="video/mp4"/>
            </video>
          </transition>
        </div>
        <navigation-process :active-direction="activeDirection"
                            @changeDirection="changeDirection"/>
      </div>
  </div>
</template>

<script>
import NavigationProcess from "@/components/entities/process/NavigationProcess.vue";
import CardProcess from "@/components/entities/process/CardProcess.vue";
import process from "@/components/entities/process/process.json"
import {mapGetters, mapState} from "vuex";

export default {
  name: "ProcessScreenNew",
  components: {CardProcess, NavigationProcess},
  data() {
    return {
      isActive: false,
      staticHeight: 0,
      isLoading: false,
      isLoadingVideo: true,
      isActiveNavigation: false,
      scrollSwiper: '',
      activeIndex: 0,
      activeDirection: 0,
      activeVideo: 'input',
      processActive: [],
      timerInterval: null
    }
  },
  created() {
    this.processActive = JSON.parse(JSON.stringify(process));
  },
  mounted() {
    this.staticHeight = window.innerHeight
  },
  unmounted() {
    clearInterval(this.timerInterval)
  },
  computed: {
    ...mapState(['isActiveProcessScreen']),
    activeDirectionText() {
      return this.activeDirection === 0 ? 'input' : this.activeDirection === 1 ? 'output' : 'pc'
    },
    processCard() {
      return this.$tm('processScreen.processCard').map((item, index) => ({
        ...item,
        ...this.processActive[index]
      })).filter(item => item.active)
    },
    filterProcess() {
      return this.processCard.filter(item => {
        switch (this.activeDirection) {
          case 0:
            return item.direction === 'input' || item.direction === 'all';
          case 1:
            return item.direction === 'output' || item.direction === 'all';
          case 2:
            return true;
        }
      }).reverse()
    }
  },
  methods: {
    startProcessScreen() {
      this.isLoading = true;
    },
    loadVideo() {
      this.isLoadingVideo = false;
      this.updateInterval();
    },
    updateInterval() {
      this.$refs.video.play();
      this.processActive = JSON.parse(JSON.stringify(process));
      clearInterval(this.timerInterval)
      const currentDate = new Date();
      this.processActive = this.processActive.map(item => {
        let date = new Date(JSON.parse(JSON.stringify(currentDate)));
        return {
          ...item,
          expireAt: new Date(date.setSeconds(date.getSeconds() + (item.timerValue || 0)))
        }
      })
      this.timerInterval = setInterval(() => {
        let item = this.processActive.find(item => item.expireAt.setMilliseconds(0) === new Date().setMilliseconds(0))
        if(item) {
          item.active = true;
        }
      }, 1000)
    },
    changeDirection(index) {
      if(this.activeDirection !== index) {
        this.countInterval = 0;
        this.isLoadingVideo = true
        this.activeDirection = index
        this.processActive = JSON.parse(JSON.stringify(process));
        this.activeVideo = this.activeDirectionText;
        this.updateInterval();
      }
    },
  }
}
</script>

<style scoped lang="scss">
.process-screen-wrapper {
  //scroll-snap-align: none;
  overflow: hidden;
  position: relative;
  background: var(--color-font-nickel);
  height: var(--static-height);
  width: 100%;
  //&.is-view {
  //  scroll-snap-align: center;
  //}
}
  .process-screen-container {
    overflow: hidden;
    position: relative;
    height: var(--static-height);
    width: 100%;

    .navigation-process-container {
      position: absolute;
      bottom: 80px;
      left: 50%;
      transform: translateX(-50%);
    }

    .process-screen-content {
      display: flex;
      flex-direction: column;
      background: rgba(var(--color-rgba-squid), .3);
      height: 100%;
      margin-left: auto;
      position: relative;
      z-index: 1;

      .main-title-container {
        margin: 0 auto;
        color: var(--color-font-white);
      }

      .process-screen-items-container {
        position: static;
        margin-right: 0;
        width: 100%;
        height: 100%;
      }

      &:after {
        pointer-events: none;
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 20%;
        z-index: 2;
        background: linear-gradient(180deg, rgba(var(--color-rgba-squid), 0) 0, #22262E 100%);
      }
    }

    .bg-container {
      z-index: 0;
      top: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0;
      transition: all .3s ease;
      &.active {
        opacity: 1;
      }
      .video {
      }
      & video {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  .card-process-container {
    position: relative;
    &:after {
      position: absolute;
      content: "";
      width: 100%;
      bottom: 0;
      background: rgba(220, 228, 248, .16);
    }
  }



  @media (max-width: 3024px){
    .process-screen-container {
      .navigation-process-container {
        bottom: 100px;
      }
      .process-screen-content {
        padding: 48px 52px;
        backdrop-filter: blur(50px);
        max-width: 560px;

        .main-title-container {
          font-size: 32px;
          margin-bottom: 80px;
        }
      }
    }

    .card-process-container {
      margin-top: 54px;
      padding-bottom: 54px;
      &:first-child {
        margin-top: 0;
      }
      &:after {
        height: 2px;
      }
    }
  }


  @media (max-width: 1920px) {
    .process-screen-container {
      .navigation-process-container {
        bottom: 80px;
      }
      .process-screen-content {
        padding: 32px 30px;
        backdrop-filter: blur(50px);
        max-width: 432px;

        .main-title-container {
          font-size: 28px;
          margin-bottom: 60px;
        }
      }
    }

    .card-process-container {
      margin-top: 36px;
      padding-bottom: 36px;
      &:first-child {
        margin-top: 0;
      }
      &:after {
        height: 2px;
      }
    }
  }


  @media (max-width: 1600px) {
    .process-screen-container {
      .navigation-process-container {
        bottom: 80px;
      }
      .process-screen-content {
        padding: 20px;
        backdrop-filter: blur(50px);
        max-width: 342px;

        .main-title-container {
          font-size: 24px;
          margin-bottom: 32px;
        }
      }
    }

    .card-process-container {
      margin-top: 26px;
      padding-bottom: 26px;
      &:after {
        height: 2px;
      }
    }
  }
  @media (max-height: 800px) {
    .process-screen-container {
      .navigation-process-container {
        bottom: 60px;
      }
      .process-screen-content {
        padding: 20px;
        backdrop-filter: blur(50px);
        max-width: 284px;

        .main-title-container {
          font-size: 20px;
          margin-bottom: 20px;
        }
      }
    }

    .card-process-container {
      &:after {
        height: 2px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .process-screen-container {
      .navigation-process-container {
        bottom: 80px;
      }
      .process-screen-content {
        padding: 20px;
        backdrop-filter: blur(50px);
        max-width: 342px;

        .main-title-container {
          font-size: 24px;
          margin-bottom: 32px;
        }
      }
    }

    .card-process-container {
      &:after {
        height: 2px;
      }
    }
  }

  @media (max-width: 1133px) {
    .process-screen-container {
      .navigation-process-container {
        bottom: 60px;
      }
      .process-screen-content {
        padding: 20px;
        backdrop-filter: blur(50px);
        max-width: 284px;

        .main-title-container {
          font-size: 20px;
          margin-bottom: 20px;
        }
      }
    }

    .card-process-container {
      &:after {
        height: 2px;
      }
    }
  }
  @media (max-width: 900px) {
    .process-screen-container {
      .navigation-process-container {
        left: 25%;
        transform: translateX(-25%);
        bottom: 32px;
      }
    }
  }

  @media (max-width: 769px) {
    .process-screen-container {
      justify-content: space-between;
      display: flex;
      flex-direction: column-reverse;
      .navigation-process-container {
        position: relative;
        top: 0;
        bottom: auto;
      }
      .process-screen-content {
        margin: 0;
        background: var(--color-font-squid);
        overflow: hidden;
        padding: 20px;
        backdrop-filter: blur(50px);
        max-width: 100%;
        max-height: 50%;

        .main-title-container {
          font-size: 20px;
          margin-bottom: 20px;
        }
      }
      .bg-container {
        position: relative;
      }
    }

    .card-process-container {
      margin-top: 8px;
      padding-bottom: 8px;
      &:after {
        height: 1px;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    //plus
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {

    .process-screen-container {
      .navigation-process-container {
        bottom: 100px;
      }
      .process-screen-content {
        padding: 100px 80px 120px;
        backdrop-filter: blur(50px);
        max-width: 960px;

        .main-title-container {
          font-size: 52px;
          margin-bottom: 120px;
        }
      }
    }

    .card-process-container {
      margin-top: 64px;
      padding-bottom: 64px;
      &:first-child {
        margin-top: 0;
      }
      &:after {
        height: 2px;
      }
    }
  }

  .disabled {
    opacity: .6;
    pointer-events: none;
  }
</style>