<template>
  <h2 v-if="activeH" class="sub-title-container text-content">
    <slot/>
  </h2>
  <div v-else class="sub-title-container text-content">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "SubTitle",
  props: {
    activeH: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped lang="scss">
  .sub-title-container {
    font-weight: 500;
    line-height: 140%;
    color: var(--color-font-squid)
  }



  @media (max-width: 3024px){
    .sub-title-container {
      font-size: 52px;
    }
  }


  @media (max-width: 1920px) {
    .sub-title-container {
      font-size: 44px;
    }
  }


  @media (max-width: 1600px) {
    .sub-title-container {
      font-size: 32px;
    }
  }
  @media (max-height: 800px) {
    .sub-title-container {
      font-size: 26px;
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .sub-title-container {
      font-size: 32px;
    }
  }

  @media (max-width: 1133px) {
    .sub-title-container {
      font-size: 26px;
    }
  }

  @media (max-width: 769px) {
    .sub-title-container {
      font-size: 18px;
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .sub-title-container {
      font-size: 18px;
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .sub-title-container {
      font-size: 80px;
    }
  }

</style>