import { createRouter, createWebHistory } from 'vue-router'
import MainPage from '../views/main-page.vue'
import MainLayout from "@/layout/MainLayout.vue";

const routes = [
  {
    path: '/',
    name: 'main',
    component: MainLayout,
    children: [
      {
        path: '/',
        name: 'home',
        component: MainPage,
      },
      {
        path: '/about',
        name: 'about',
        component: MainPage,
      },
      {
        path: '/features',
        name: 'features',
        component: MainPage,
      },
      {
        path: '/applications',
        name: 'applications',
        component: MainPage,
      },
      {
        path: '/price',
        name: 'price',
        component: MainPage,
      },
      {
        path: '/developer',
        name: 'developer',
        component: MainPage,
      },
      {
        path: '/support',
        name: 'support',
        component: MainPage,
      },
      {
        path: '/contact',
        name: 'contact',
        component: MainPage,
      },
      {
        path: '/calculator',
        name: 'calculator',
        component: MainPage,
      },
      {
        path: '/calculation',
        name: 'calculation',
        component: MainPage,
      },
      {
        path: '/consultation',
        name: 'consultation',
        component: MainPage,
      }
    ]
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return false
  },
})

export default router
