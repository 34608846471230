import { createStore } from 'vuex'

export default createStore({
  state: {
    activeMobile: false,
    activeMiniMobile: false,

    isActiveModalDemo: false,
    isActiveModalContact: false,
    isActiveModalTariff: false,
    isActiveModalCookie: false,
    isActiveModalPartner: false,

    isActiveProcessScreen: false,
    isActiveScroll: false,
    timerScroll: null,

    innerWidthCustom: 0,
    innerHeightCustom: 0
  },
  getters: {
  },
  mutations: {
    SET_ACTIVE_MOBILE(state, payload) {
      state.activeMobile = payload
    },
    SET_ACTIVE_IS_MINI_MOBILE(state, payload) {
      state.activeMiniMobile = payload
    },
    SET_ACTIVE_SCROLL(state, payload) {
      state.isActiveScroll = payload
    },

    SET_TIMER_SCROLL(state, payload) {
      clearTimeout(state.timerScroll)
      state.timerScroll = payload
    },
    SET_ACTIVE_PROCESS_SCREEN(state, payload) {
      state.isActiveProcessScreen = payload
    },
    SET_INNER_WIDTH(state, payload) {
      state.innerWidthCustom = payload
    },
    SET_INNER_HEIGHT(state, payload) {
      state.innerHeightCustom = payload
    },

    SET_IS_ACTIVE_MODAL_DEMO(state, payload) {
      state.isActiveModalDemo = payload
    },
    SET_IS_ACTIVE_MODAL_CONTACT(state, payload) {
      state.isActiveModalContact = payload
    },
    SET_IS_ACTIVE_MODAL_TARIFF(state, payload) {
      state.isActiveModalTariff = payload
    },
    SET_IS_ACTIVE_MODAL_PARTNER(state, payload) {
      state.isActiveModalPartner = payload
    },
    SET_IS_ACTIVE_MODAL_COOKIE(state, payload) {
      state.isActiveModalCookie = payload
    },
  },
  actions: {
    ACTIVE_MOBILE: (context, payload) => {
      context.commit('SET_ACTIVE_MOBILE', payload)
    },
    ACTIVE_IS_MINI_MOBILE: (context, payload) => {
      context.commit('SET_ACTIVE_IS_MINI_MOBILE', payload)
    },
    ACTIVE_SCROLL: (context, payload) => {
      context.commit('SET_ACTIVE_SCROLL', payload)
    },
    TIMER_SCROLL: (context, payload) => {
      context.commit('SET_TIMER_SCROLL', payload)
    },
    ACTIVE_PROCESS_SCREEN: (context, payload) => {
      context.commit('SET_ACTIVE_PROCESS_SCREEN', payload)
    },

    INNER_WIDTH: (context, payload) => {
      context.commit('SET_INNER_WIDTH', payload)
    },
    INNER_HEIGHT: (context, payload) => {
      context.commit('SET_INNER_HEIGHT', payload)
    },

    IS_ACTIVE_MODAL_DEMO: (context, payload) => {
      context.commit('SET_IS_ACTIVE_MODAL_DEMO', payload)
    },
    IS_ACTIVE_MODAL_CONTACT: (context, payload) => {
      context.commit('SET_IS_ACTIVE_MODAL_CONTACT', payload)
    },
    IS_ACTIVE_MODAL_PARTNER: (context, payload) => {
      context.commit('SET_IS_ACTIVE_MODAL_PARTNER', payload)
    },
    IS_ACTIVE_MODAL_TARIFF: (context, payload) => {
      context.commit('SET_IS_ACTIVE_MODAL_TARIFF', payload)
    },
    IS_ACTIVE_MODAL_COOKIE: (context, payload) => {
      context.commit('SET_IS_ACTIVE_MODAL_COOKIE', payload)
    },
  },
  modules: {
  }
})
